import React,{useState} from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import Loader from "../Loader/Loader";
import "./hero.css"

const Hero = () => {

    const [loading, setLoading] = useState(false);
    const array = [
        { img: "6.jpg" },
        { img: "4.jpg" },
        { img: "foot.jpg" },
        { img: "AdobeStock_246454872_Preview.jpeg" },
        { img: "3.jpg" },
    ]
    const move = useNavigate()

    return <>
        <div className='container-fluid px-0 mx-0' id='/'>
            <div className='row'>
                <div className='col-lg-12 px-0' >
                    <Swiper
                        slidesPerView={1}
                        autoplay={{ delay: 4500 }}
                        modules={[Autoplay]}
                        className="mySwiper">
                        {array?.map((item, index) => {
                            return <SwiperSlide className='hero_slide' key={index}>
                                <img src={item.img ? item?.img : "/loader.jpg"} alt="No network"
                                    style={{ opacity: loading ? 0 : 1, transition: "opacity 0.5s ease-in-out" }}
                                />
                                <a href="/products/all">
                                    <button className='btn hero_shop'>SHOP NOW</button>
                                </a>
                            </SwiperSlide>
                        })}
                    </Swiper>
                </div>
            </div>
        </div>

    </>
}

export default Hero